import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import config from "../../app/config";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import EventsScheme from "../../features/events/eventsEnum";
import { selectEvent } from "../../features/events/eventsSlice";
import { formatDate } from "../../helpers";
import { DECLINED_PHASE, DISPLAY_PHASE, getEventPhase, GOING_PHASE, RESERVATION_PHASE, TAKEOVER_PHASE } from "../../helpers/eventPhases";
import { Image } from "../image";
import Loading from "../loading";

interface EventPopupSceme {
  isOpened: boolean,
  close: () => void,
  event: number,
  slug: string,
  total_tables: number
}

const EventPopup = (props: EventPopupSceme) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { events } = useAppSelector(state => state.events);
  const { place } = useAppSelector(state => state.place);
  const { currencies } = useAppSelector(state => state.currencies);
  // translation
  const {t} = useTranslation(['general'])

  let startReservation = () => {
    dispatch(selectEvent(events[props.event]));
    // if event is not for 3D, redirect to request
    if(place.type === 1 || events[props.event].event_mode === 'request')
      navigate("/request");
  }

  // function to display minimum number of bottle per table
  let displayBottlePerTable = () => {
    if(events[props.event].minimum_bottles_classic || events[props.event].minimum_bottles_vip)
      return t("min_bottles", { normal: events[props.event].minimum_bottles_classic, vip: events[props.event].minimum_bottles_vip })
    else
      return t("min_bottles_no_restrictions");
  }
  
  return (
    <div className={"popup" + (props.isOpened ? " opened" : "")}>
      {
        props.event !== -1 && (
          <div className="event-details">
            <div>
              {/* <!-- Popup actions --> */}
              <div className="popup__actions">
                <div className="close-popup hover--translate-left" onClick={props.close}>
                  <img src="../assets/icons/back-arrow.svg" alt=""/>
                </div>
              </div>
              {/* <!-- / Popup actions --> */}
              <div className="event-details__banner">
                <Image src={config.assets + props.slug + events[props.event].banner} alt="" fadeIn />
              </div>
              {/* <!-- Event details main info --> */}
              <div className="event-details__box">
                <EventStatus {...events[props.event]}  />
                <div className="title">{ events[props.event].name }</div>
                <div className="event-details__when">
                  <div className="event-details__icon-box">
                    <img src="../assets/icons/calendar.svg" alt="" />
                    <div className="label">{ formatDate(new Date(events[props.event].event_start), 1, t) }</div>
                  </div>
                  <div className="event-details__icon-box">
                    <img src="../assets/icons/clock.svg" alt="" />
                    <div className="label">{ formatDate(new Date(events[props.event].event_start), 3 )+ ' - ' + formatDate(new Date(events[props.event].event_end), 3) }</div>
                  </div>
                </div>
              </div>
              {/* <!-- / Event details main info --> */}
              {/* <!-- Event details info --> */}
              <div className="grid row-30">
                <div className="event-details__single">
                  <div className="event-details__single-name">{t("takeover_label")}</div>
                  <h5>{t("until_text", {date: formatDate(new Date(events[props.event].reservation_takeover), 3)})}</h5>
                </div>
                {/* <div className="event-details__single">
                  <div className="event-details__single-name">{t("min_age_label")}</div>
                  <h5>{t("min_age_text", {age: events[props.event].min_age})}</h5>
                </div> */}
                <div className="event-details__single">
                  <div className="event-details__single-name">{t("reservation_mode")}</div>
                  <h5>{t(`${events[props.event].event_mode}_mode`, { ns: 'general' })}</h5>
                </div>
                <div className="event-details__single">
                  <div className="event-details__single-name">{t("entrance_label")}</div>
                  <h5>{(parseInt(events[props.event].ticket_price) === 0) ? t("free_text") : events[props.event].ticket_price + ' ' + currencies[events[props.event].ticket_currency].symbol}</h5>
                </div>
                <div className="event-details__single big">
                  <div className="event-details__single-name">{t("min_bottles_title")}</div>
                  <h5>{displayBottlePerTable()}</h5>
                </div>
                {
                  events[props.event].dress_code?.length > 0 &&
                    <div className="event-details__single big">
                      <div className="event-details__single-name">{t("dress_code_title")}</div>
                      <h5>{events[props.event].dress_code}</h5>
                    </div>
                }
                {
                  events[props.event].event_notes?.length > 0 &&
                    <div className="event-details__single big">
                      <div className="event-details__single-name">{t("event_notes_title")}</div>
                      <h5>{events[props.event].event_notes}</h5>
                    </div>
                }
              </div>
              {/* <!-- Event details info --> */}
            </div>
            <EventPopupActions {...events[props.event]} place_type={place.type} startReservation={startReservation} />
          </div>
        )
      }
    </div>
  )
}

const EventPopupActions = (props: EventsScheme & { place_type: number, startReservation: () => void }) => {
  let event_phase = getEventPhase(props.reservation_takeover, props.event_start, props.event_end, props.reservation_start, props.event_cancelled);
  const {t} = useTranslation(['general', 'buttons']);

  if(event_phase === RESERVATION_PHASE) {
    if(props.event_booked)
      return <div className="reservation-available"><h5>{t("all_tables_reserved_text")}</h5></div>

    return <div className="button popup__button" onClick={props.startReservation}>{t((props.place_type === 0 && props.event_mode === 'choose_table') ? "reserve_table" : "request_a_table", {ns: 'buttons'})}<img src="../assets/icons/button-arrow.svg" /></div>
  }
  else if(event_phase === GOING_PHASE || event_phase === TAKEOVER_PHASE)
    return <div className="reservation-available"><h5>{t("reservation_unavailable_text")}</h5></div>
  else if(event_phase === DISPLAY_PHASE) 
    return <div className="reservation-available">
      <h5>{t("can_reserve_text", {date: formatDate(new Date(props.reservation_start), 2), time: formatDate(new Date(props.reservation_start), 3)})}</h5>
    </div>
  else
    return <></>
}

const EventStatus = (props: EventsScheme ) => {
  let event_phase = getEventPhase(props.reservation_takeover, props.event_start, props.event_end, props.reservation_start, props.event_cancelled);
  const {t} = useTranslation(['status']);

  if(event_phase === DECLINED_PHASE)
    return <div className="event-details__cancelled">{t("declined_phase", {ns: 'status'})}</div>

  if(props.event_booked)
    return <div className="event-details__cancelled">{t("all_reserved", {ns: 'status'})}</div>
  
  if(event_phase === GOING_PHASE || event_phase === TAKEOVER_PHASE)
    return <div className="event-details__going">{t("going_phase", {ns: 'status'})}</div>
  
  return <></>
}

export default EventPopup;